import React, { ReactNode, useState } from "react";
import MainDrawer from "./MainDrawer/MainDrawer";
import TopNav from "./TopNav/TopNav";
import styles from "./Wrapper.module.scss";
import Drawer from "rc-drawer";
interface WrapperProps {
  children: ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <div className={styles.wrapper}>
      <div className={styles.parent}>
        {/* <Drawer open={openDrawer}>
          <MainDrawer />
        </Drawer> */}
        <div
          className={styles.mobileMenu}
          style={{ transform: `translateX(${openDrawer ? "0" : "-100%"})` }}
        >
          <MainDrawer />
        </div>
        {openDrawer && (
          <div onClick={() => setOpenDrawer(false)} className={styles.bg}></div>
        )}

        <div className={styles.menu}>
          <div className={styles.webMenu}>
            <MainDrawer />
          </div>
        </div>
        <aside className={styles.aside}>
          <div className={styles.topNav}>
            <TopNav setOpen={setOpenDrawer} open={openDrawer} />
          </div>

          {children}
        </aside>
      </div>
    </div>
  );
};

export default Wrapper;
