import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import styles from "../styles/Home.module.scss";
import { RootState } from "../redux/Config/root-store";
import { useSelector, useDispatch } from "react-redux";
import { decrement, increment } from "../redux/Authentication/AuthReducer";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect } from "react";
import { baseUrl } from "../base-config/base";
import { ApiKit } from "../redux/Base/base";
import Wrapper from "../components/Wrapper/Wrapper";
import Dashboard from "../components/dashboard";
// Or from '@reduxjs/toolkit/query/react'

export const apiJokes = createApi({
  reducerPath: "apiJokes",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    getJokeByType: builder.query({
      query: (type) => `/search?name=${type}`,
    }),
  }),
});

export const { useGetJokeByTypeQuery } = apiJokes;

const Home: NextPage = () => {
  const redux_data = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const { data, error, isLoading } = useGetJokeByTypeQuery("asia");

  console.log(redux_data.base.token, "token");

  return (
    <>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Wrapper>
        {" "}
        <Dashboard />
      </Wrapper>
    </>
  );
};

export default Home;
